<template>
   <div class="card-body">
        <b-tabs content-class="mt-3">
            <activity-log :inner-key="key" :id="id"></activity-log>
        </b-tabs>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-employees",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'employee/employees',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'Employee',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.employees_management")}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>