<template>
    <div class="form-group row mt-8">

        <div class="col-lg-6 mb-5">
            <label>{{$t('employees.title')}}</label>
            <input v-model="data.title" type="text" class="form-control" :class="validation && validation.title ? 'is-invalid' : ''"/>
            <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.title[0] }}
                </span>
        </div>
        <div class="col-lg-6 mb-5">
            
            <label>{{$t('status')}}</label>
            <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
            </select>
            <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.status[0] }}
                </span>
        </div>

        <div class="col-lg-6 mb-5">
            <label>{{$t('employees.notes')}}</label>
            <textarea type="text" v-model="data.notes" class="form-control" :class="  validation && validation.notes ? 'is-invalid' : ''"></textarea>
            <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                {{ validation.notes[0] }}
            </span>
        </div>

        <div class="col-lg-12 mb-8 text-center">
            <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
        </div>
        <div class="col-lg-12 mb-5 mt-15">
            <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tableNotes">

                <template slot="actions" slot-scope="props">
                    <v-icon small class="mr-2 text-info" @click="editItem(props.row)">mdi-pencil</v-icon>
                    <v-icon small class="text-danger" @click="deleteItem(props.row)">mdi-delete</v-icon>
                </template>
            </v-server-table>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "Notes",
        props:['employeeId'],
        data() {
            return {
                mainRoute: 'employee/employees_notes',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'employees',

                data: {
                    employee_id: null,
                    title: null,
                    notes: null,
                  status: null,
                },
                validation: null,
                columns: ['title', 'notes', 'status_name', 'actions'],
                idEditing: null,
                status_list: [],
            };
        },
        watch:{
            employeeId: function (val) {
                if (val){
                    this.data.employee_id = val;
                }
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        title: that.$t('employees.title'),
                        notes: that.$t('employees.notes'),
                        status_name: that.$t('status'),
                        actions: that.$t('actions'),

                    },

                    filterByColumn: false,
                    customFilters: [],
                    filterable: false,
                    orderBy: {'column': 'id'},
                    sortable: [],
                    alwaysShowPerPageSelect: false,
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            // filter: data.query,
                            employee_id: that.employeeId,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        methods: {
            getFetch() {
                this.$refs.tableNotes.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },
            editItem(item) {
                this.idEditing = item.id;
                ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
                    this.isEditing = true;
                    this.data.employee_id = response.data.data.employee_id;
                    this.data.title = response.data.data.title;
                    this.data.notes = response.data.data.notes;
                    this.data.status = response.data.data.status;
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            afterSave() {
                this.data = {
                    employee_id: this.employeeId,
                    title: null,
                    notes: null,
                };
                this.idEditing = null;
                this.isEditing = false;
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
        },
        mounted() {
            this.data.employee_id = this.employeeId;
            this.getStatusList();
        },
    }
</script>

<style scoped>

</style>